import React from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Layout from "../components/Layout";
import Hero from "../components/Hero";

import MissionSidebar from "../components/Mission/mission-sidebar";
import HeroHeaderTeaching from "../components/Blog/hero-header-teaching";
import MissionStats from "../components/Mission/mission-stats";

export const query = graphql`
  query ($slug: String!) {
    contentfulMission(slug: { eq: $slug }) {
      description {
        raw
      }
      income
      language
      location
      population
      missionAreaName
      religion
      climate
      slug
      featuredImage {
        file {
          url
        }
      }
    }
  }
`;

const MissionTemplate = (props) => {
  return (
    <Layout>
      <Hero
        bgImage={props.data.contentfulMission.featuredImage.file.url}
        title="Domestic and International Mission Trips"
      />

      <MissionStats
        title={props.data.contentfulMission.missionAreaName}
        population={props.data.contentfulMission.population}
        location={props.data.contentfulMission.location}
        language={props.data.contentfulMission.language}
        religion={props.data.contentfulMission.religion}
        climate={props.data.contentfulMission.climate}
        income={props.data.contentfulMission.income}
      />
      <section className="container w-full mx-auto p-4 lg:px-8 py-8">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="w-full md:w-2/3">
            <div>
              <img
                src={props.data.contentfulMission.featuredImage.file.url}
                alt={props.data.contentfulMission.missionAreaName}
                className="object-cover h-96 w-full rounded-2xl mb-8"
              />
            </div>
            <HeroHeaderTeaching
              title1="Mission Trip"
              tagLine1={props.data.contentfulMission.missionAreaName}
            />

            <p>
              {documentToReactComponents(
                JSON.parse(props.data.contentfulMission.description.raw)
              )}
            </p>
          </div>
          <div className="w-full md:w-1/3">
            <MissionSidebar />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MissionTemplate;
